<template>
	<div class="equipment">
		<div class="top-title1">
			<van-icon name="arrow-left" class="icon-fan" @click="fan()" />
			<span>特种设备列表</span>
		</div>

		<div class="top-title">
			<img class="left-img" src="../img/e-title.png" />
			<span>特种设备列表</span>
		</div>

		<van-pull-refresh v-model="isDownLoading" @refresh="onRefresh" class="pt0px">
			<div v-for="(item,index) in dtWinNumberInfos" :key="index" class="list">
				<div class="list-top">
					<img src="../img/e-top.png" />
					<div class="lt-number">{{index+1}}</div>
					<div class="lt-title">{{item.facilityName}}</div>
				</div>
				<div class="list-content">
					<div class="lc-div">
						<span>设备型号：</span>
						{{isNull(item.facilityArchives)}}
					</div>
					<div class="lc-div">
						<span>设备状态：</span>
						{{isNull(item.facilityStateEn)}}
					</div>
					<div class="lc-div">
						<span>设备类型：</span>
						{{isNull(item.facilityTypeName)}}
					</div>
				</div>
				<div class="list-bottom">
					<van-button color="#025193" class="lb-button" :to="{ path: '/equipmentD/'+item.facilityQrNumber}">设备详情</van-button>
				</div>
			</div>
			<van-list v-model:loading="isUpLoading" :finished="upFinished" :finished-text="nullTip" @load="onLoad" />
		</van-pull-refresh>

		<equipment-no v-if="!dtWinNumberInfos||dtWinNumberInfos.length==0"></equipment-no>
	</div>
</template>

<style scoped>
	body {
		width: 100%;
		margin: 0px;
		padding: 0px;
		background-color: #eae6e5;
	}

	.equipment {
		height: 100%;
		background-color: #eae6e5;
	}

	.top-title1 {
		background-color: #025193;
		color: white;
		width: 100%;
		height: 1.173333rem;
		line-height: 1.173333rem;
		position: fixed;
		z-index: 100;
		text-align: center;
		font-size: 0.49rem;
		letter-spacing: 0.026667rem;
	}

	.icon-fan {
		position: absolute;
		left: 0.133333rem;
		color: white;
		top: 0.24rem;
		font-size: 0.746667rem;
	}

	.top-title {
		width: 100%;
		height: 1.173333rem;
		line-height: 1.173333rem;
		padding-top: 1.173333rem;
		position: relative;
		background-color: #eae6e5;
		color: #232122;
	}

	.top-title span {
		font-size: 0.48rem;
		font-weight: 550;
		letter-spacing: 0.026667rem;
		margin-left: 1.12rem;
	}

	.top-title .left-img {
		height: 0.426667rem;
		position: absolute;
		top: 1.463333rem;
		left: 0.266667rem;
	}

	.top-title .add-img {
		height: 0.533333rem;
		float: right;
		position: absolute;
		top: 1.42rem;
		right: 0.266667rem;
		z-index: 999;
	}

	.pt0px {
		padding-top: 0rem;
		background-color: #eae6e5;
	}

	.list {
		width: 96%;
		margin-bottom: 0.4rem;
		margin-left: 2%;
		background-color: #fff;
		position: relative;
	}

	.list-top {
		height: 1.2rem;
		line-height: 1.2rem;
		position: relative;
		border-bottom: 1px #025193 solid;
		width: 100%;
	}

	.list-top img {
		width: 50%;
		position: absolute;
		top: 0px;
		left: 25%;
	}

	.list-top .lt-number {
		background-color: #025193;
		color: white;
		position: absolute;
		width: 0.8rem;
		height: 0.8rem;
		line-height: 0.8rem;
		top: 0.133333rem;
		left: 3%;
		text-align: center;
		font-size: 0.373333rem;
	}

	.list-top .lt-title {
		display: inline-block;
		margin-left: 1.6rem;
		font-size: 0.373333rem;
	}

	.list-content {
		width: 94%;
		margin-left: 3%;
		padding-top: 0.133333rem;
		padding-bottom: 0.133333rem;
	}

	.list-content .lc-div {
		width: 100%;
		font-size: 0.373333rem;
		height: 0.666667rem;
		line-height: 0.666667rem;
		color: #459bf5;
	}

	.list-content .lc-div span {
		font-size: 0.373333rem;
		color: #000;
	}

	.list-bottom {
		width: 94%;
		margin-left: 3%;
		position: relative;
		padding-bottom: 0.2rem;
		height: 0.933333rem;
	}

	.list-bottom .lb-button {
		height: 0.933333rem;
		line-height: 0.933333rem;
		width: 2.666667rem;
		text-align: center;
		color: #fff;
		background-color: #025193;
		font-size: 0.373333rem;
		position: absolute;
		right: 0px;
		border-radius: 0.133333rem;
	}
</style>

<script>
	import Equipment from "../js/Equipment";
	import EquipmentNo from "./EquipmentNo.vue";
	export default {
		components: { EquipmentNo },
		...Equipment,
	};
</script>
