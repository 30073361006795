import { getCurrentInstance, reactive, toRefs, ref } from "vue";
import { useRouter } from 'vue-router'

export default {
	setup() {
		//开始创建组件之前
		const { proxy } = getCurrentInstance();
		
		const router = useRouter();

		let dataAll = reactive({
			equipmentData: {},
			show: ref(false),
			nullTip: '',
			pageSize: 10, // 每页条数
			pageIndex: 0, // 页码
			dtWinNumberInfos: [], // 请求数据
			isUpLoading: false, // 上拉加载
			upFinished: false, // 上拉加载完毕
			isDownLoading: false, // 下拉刷新
			offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
			urlImg: [], 
		});

		const showPopup = (url) => {
			dataAll.urlImg = [url];
			dataAll.show = true;
		};

		const onLoad = () => {
			console.log(dataAll.isDownLoading);
			if (dataAll.isDownLoading) {
				dataAll.dtWinNumberInfos = [];
				dataAll.isDownLoading = false;
				dataAll.pageIndex = 1;
			} else {
				dataAll.pageIndex++;
			}

			proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/facilityPage`),
				method: 'post',
				headers:{
					token: sessionStorage.getItem("userToken")
			  	},
				data: proxy.$http.adornData({
					'enterpriseQrNumber': proxy.$router.currentRoute.value.params.id,
					'page': dataAll.pageIndex,
					'limit': dataAll.pageSize
				})
			}).then(({
				data
			}) => {
				console.log(data);
				if (data && data.code === 0) {
					dataAll.dtWinNumberInfos.push(...data.page.list);
					if (data.page.currPage >= data.page.totalPage) {
						dataAll.upFinished = true;
					} else if (data.code === 401) {
						sessionStorage.setItem("userToken", "");
						router.push({
							path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
						});
					} else {
						dataAll.isUpLoading = false;
					}
				} else {
					proxy.$toast(data.msg);
					dataAll.isUpLoading = true;
				}
			})
		};

		const onRefresh = () => {
			// 清空列表数据
			dataAll.upFinished = false;
			// 重新加载数据
			// 将 isUpLoading 设置为 true，表示处于加载状态
			dataAll.isUpLoading = true;
			onLoad();
		};


		const isNull = (value) => {
			if (value == "" || value == null) {
				return "暂无";
			} else {
				return value;
			}
		}

		const fan = () => {
			router.push({
				path: "/enterpriseJump/" + sessionStorage.getItem("enterpriseQrNumber")
			});
		}

		return {
			...toRefs(dataAll),
			onLoad,
			onRefresh,
			showPopup,
			isNull,
			fan,
		}
	}
}
