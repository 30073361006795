<template>
	<div class="enterpriseNo">
		<div class="alls">
			<img src="../img/notET.png" class="imgTsT" />
			<img src="../img/noequipment.png" class="imgTsC" />
		</div>
	</div>
</template>

<script>
	export default {
		setup() {
			document.title = "暂无设备";
		},
	};
</script>

<style scoped>
	.alls {
		width: 100%;
		padding-top: 1.173333rem;
		padding-bottom: 1.666667rem;
		position: relative;
	}

	.imgTsT {
		width: 30%;
		margin-left: 65%;
		margin-top: 0.266667rem;
	}

	.imgTsC {
		width: 70%;
		margin-left: 15%;
	}
</style>